@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Applies Open/Close transitions to the Modals used from the react-modal npm package */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  scale: 0.0;
  transition: opacity 200ms ease-in-out, scale 200ms ease-in-out;
}

.ReactModal__Content--after-open{
  scale: 1.0;
  opacity: 1;
}

.ReactModal__Content--before-close{
  scale: 0.0;
  opacity: 0;
}

.amplify-button--primary:hover {
  background-color: #008CF7;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #3b82f6;
  --amplify-components-button-primary-active-background-color: #3b82f6;
  --amplify-internal-button-background-color: #3b82f6;
  --amplify-components-button-link-color: #3b82f6;
}